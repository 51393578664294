/*  ==========================================================================
    LINKBOXES
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__linkboxes--color: $clr__typo--secondary;
$module-clr__linkboxes--bg: $clr__ci--primary;

/*
 * MODULE
 */

.bn-linkboxes {
  .bn-linkboxes__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .bn-linkboxes__item {
      display: inline-block;
      position: relative;
      width: calc(50% - 10px);
      margin-bottom: 20px;
      .bn-linkboxes__image {
        display: block;
        > div {
          overflow: hidden;
        }
        img {
          @include img--responsive();
        }
      }
      .bn-linkboxes__title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        height: 100%;
        background: rgba($module-clr__linkboxes--bg, 0.5);
        transition: background .2s;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        > span {
          font-size: 2.5em;
          color: $clr__typo--secondary;
          text-transform: uppercase;
          padding: 0 10px;
        }
      }
      &:hover {
        .bn-linkboxes__title {
          background: $module-clr__linkboxes--bg;
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-linkboxes {
    .bn-linkboxes__list {
      .bn-linkboxes__item {
        width: 100%;
      }
    }
  }
}