/*  ==========================================================================
    DOWNLOADBOXES
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__downloadboxes--bg: $clr__ci--primary;
$module-clr__downloadboxes--border: $clr__ci--primary;
$module-clr__downloadboxes--text-primary: $clr__ci--primary;
$module-clr__downloadboxes--text-secondary: $clr__white;
$module-clr__downloadboxes--accent: $clr__ci--secondary;

/*
 * MODULE
 */

.bn-downloadboxes {
  > .bn-grid__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .bn-downloadboxes__item {
    flex: none !important;
    flex-grow: 1;
    margin-top: 20px;
    width: calc(50% - 10px);
    font-family: $typo__ff--secondary;
    > div {
      color: $module-clr__downloadboxes--text-primary;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      position: relative;
      &:before,
      &:after {
        background-color: $module-clr__downloadboxes--border;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 5;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
    }
    .bn-downloadboxes__image {
      display: block;
      overflow: hidden;
      width: 35%;
      img {
        @include img--cover-v2();
      }
    }
    .bn-downloadboxes__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px;
      width: 65%;
      > div {
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
        > div {
          width: 100%;
        }
        &:first-child {
          align-items: flex-start;
          padding-bottom: 20px;
        }
      }
      .bn-downloadboxes__title {
        @include typo--subheadline($module-clr__downloadboxes--text-primary);
        font-family: $typo__ff--secondary !important;
      }
      .bn-downloadboxes__link,
      .bn-downloadboxes__file {
        @include button--text($module-clr__downloadboxes--text-primary,$module-clr__downloadboxes--text-primary);
      }
      .bn-downloadboxes__link {
        margin-bottom: 10px;
      }
    }
    &:nth-child(4n+2),
    &:nth-child(4n+3) {
      > div {
        background-color: $module-clr__downloadboxes--bg;
        color: $module-clr__downloadboxes--text-secondary;
        &:before,
        &:after {
          display: none;
        }
      }
      .bn-downloadboxes__content {
        .bn-downloadboxes__title {
          @include typo--subheadline($module-clr__downloadboxes--text-secondary);
        }
        .bn-downloadboxes__link,
        .bn-downloadboxes__file {
          @include button--text($module-clr__downloadboxes--text-secondary,$module-clr__downloadboxes--accent);
        }
      }
    }
    &:nth-child(-n+2) {
      margin-top: 0;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-downloadboxes {
    .bn-downloadboxes__item {
      margin-top: 20px !important;
      width: 100%;
      &:nth-child(odd) {
        > div {
          background-color: transparent;
          color: $module-clr__downloadboxes--text-primary;
          &:before,
          &:after {
            display: block;
          }
        }
        .bn-downloadboxes__content {
          .bn-downloadboxes__title {
            @include typo--subheadline($module-clr__downloadboxes--text-primary);
          }
          .bn-downloadboxes__link,
          .bn-downloadboxes__file {
            @include button--text($module-clr__downloadboxes--text-primary,$module-clr__downloadboxes--accent);
          }
        }
      }
      &:nth-child(even) {
        > div {
          background-color: $module-clr__downloadboxes--bg;
          color: $module-clr__downloadboxes--text-secondary;
          &:before,
          &:after {
            display: none;
          }
        }
        .bn-downloadboxes__content {
          .bn-downloadboxes__title {
            @include typo--subheadline($module-clr__downloadboxes--text-secondary);
          }
          .bn-downloadboxes__link,
          .bn-downloadboxes__file {
            @include button--text($module-clr__downloadboxes--text-secondary,$module-clr__downloadboxes--accent);
          }
        }
      }
      &:first-child {
        margin-top: 0 !important;
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-downloadboxes {
    .bn-downloadboxes__item {
      > div {
        display: block;
      }
      .bn-downloadboxes__image,
      .bn-downloadboxes__content {
        width: 100%;
      }
      .bn-downloadboxes__content {
        padding: 20px;
      }
    }
  }
}