/*  ==========================================================================
   FREEROOMS LIST VIEW
   ========================================================================== */

/*
 * COLORS
 */

$module-clr__freerooms-list-view--bg-primary: $clr__ci--primary;
$module-clr__freerooms-list-view--bg-secondary: $clr__white;
$module-clr__freerooms-list-view--border-primary: $clr__ci--primary;
$module-clr__freerooms-list-view--border-secondary: $clr__ci--primary;
$module-clr__freerooms-list-view--price-primary: $clr__ci--primary;
$module-clr__freerooms-list-view--price-secondary: $clr__ci--primary;
$module-clr__freerooms-list-view--price-typo-primary: $clr__typo--secondary;
$module-clr__freerooms-list-view--price-typo-secondary: $clr__typo--secondary;
$module-clr__freerooms-list-view--typo-primary: $clr__typo--secondary;
$module-clr__freerooms-list-view--typo-secondary: $clr__ci--primary;

/*
 * MODULE
 */

.bn-freerooms-list-view {
  .bn-freerooms-list-view__holder {
    display: flex;
    flex-wrap: wrap;
    .bn-freeroom-box {
      background-color: $module-clr__freerooms-list-view--bg-primary;
      border-bottom: 2px solid $module-clr__freerooms-list-view--border-primary;
      color: $module-clr__freerooms-list-view--typo-primary;
      margin: 105px 0 0 15px;
      position: relative;
      width: calc(33.33% - 10px);
      a {
        color: $module-clr__freerooms-list-view--typo-primary;
        &:visited {
          color: $module-clr__freerooms-list-view--typo-primary;
        }
        &:hover {
          color: $module-clr__freerooms-list-view--typo-primary;
        }
      }
      .bn-freeroom-box__image {
        background-color: $clr__black;
        display: block;
        overflow: hidden;
        img {
          @include img--responsive();
        }
      }
      .bn-freeroom-box__labels {
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        top: -65px;
        width: 100%;
        z-index: 10;
        .bn-freeroom-box__label {
          background-color: $clr__white;
          color: $module-clr__freerooms-list-view--price-typo-primary;
          border-radius: 20px;
          display: block;
          height: 130px;
          margin-left: 20px;
          min-width: 130px;
          overflow: hidden;
          > div {
            background-color: $module-clr__freerooms-list-view--price-primary;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding: 10px;
            position: relative;
            text-align: center;
          }
          .bn-icon {
            display: inline-block;
            font-size: 4.0em;
            margin-bottom: 10px;
          }
          .bn-freeroom-box__amount-addition {
            font-size: 1.6em;
            margin-right: 5px;
          }
          .bn-freeroom-box__amount {
            font-size: 2.5em;
          }
          &.bn-freeroom-box__label--old-price {
            > div {
              background-color: rgba($module-clr__freerooms-list-view--price-primary,0.7);
            }
            .bn-freeroom-box__amount {
              text-decoration: line-through;
            }
          }
          &:first-child {
            margin-left: 0;
          }
        }
      }
      .bn-freeroom-box__content {
        padding: 40px 40px 130px 40px;
        text-align: center;
        .bn-freeroom-box__name {
          @include typo--subheadline($module-clr__freerooms-list-view--typo-primary);
        }
        .bn-freeroom-box__infos {
          font-size: 1.6em;
          margin-top: 10px;
        }
        .bn-freeroom-box__dates {
          font-size: 2.0em;
          margin-top: 40px;
        }
        .bn-freeroom-box__button {
          bottom: 40px;
          left: 0;
          padding: 0 40px;
          position: absolute;
          width: 100%;
          z-index: 5;
          .bn-button {
            @include button--cta();
          }
        }
      }
      // remove margin left on first and every fourth box
      &:first-child,
      &:nth-child(3n+4) {
        margin-left: 0;
      }
      // change margin top on first 3 boxes
      &:nth-child(-n+3) {
        margin-top: 65px;
      }
      // recolor every second box
      &:nth-child(even) {
        background-color: $module-clr__freerooms-list-view--bg-secondary;
        border-color: $module-clr__freerooms-list-view--border-secondary;
        color: $module-clr__freerooms-list-view--typo-secondary;
        a {
          color: $module-clr__freerooms-list-view--typo-secondary;
          &:visited {
            color: $module-clr__freerooms-list-view--typo-secondary;
          }
          &:hover {
            color: $module-clr__freerooms-list-view--typo-secondary;
          }
        }
        .bn-freeroom-box__labels {
          .bn-freeroom-box__label {
            color: $module-clr__freerooms-list-view--price-typo-secondary;
            > div {
              background-color: $module-clr__freerooms-list-view--price-secondary;
            }
            &.bn-freeroom-box__label--old-price {
              > div {
                background-color: rgba($module-clr__freerooms-list-view--price-secondary,0.7);
              }
            }
          }
        }
        .bn-freeroom-box__content {
          .bn-freeroom-box__name {
            @include typo--subheadline($module-clr__freerooms-list-view--typo-secondary);
          }
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// laptop
@media #{$mq__laptop} {
  .bn-freerooms-list-view {
    .bn-freerooms-list-view__holder {
      .bn-freeroom-box {
        margin-top: 90px;
        .bn-freeroom-box__labels {
          top: -50px;
          .bn-freeroom-box__label {
            height: 100px;
            margin-left: 10px;
            min-width: 100px;
            .bn-icon {
              font-size: 3.0em;
              margin-bottom: 5px;
            }
            .bn-freeroom-box__amount-addition {
              font-size: 1.4em;
              margin-right: 0;
            }
            .bn-freeroom-box__amount {
              font-size: 2.0em;
            }
          }
        }
        .bn-freeroom-box__content {
          padding-bottom: 130px;
          .bn-freeroom-box__button {
            .bn-button {
              min-width: 0 !important;
              width: 100% !important;
            }
          }
        }
        // change margin top on first 3 boxes
        &:nth-child(-n+3) {
          margin-top: 50px;
        }
      }
    }
  }
}

// tablet
@media #{$mq__tablet} {
  .bn-freerooms-list-view {
    .bn-freerooms-list-view__holder {
      .bn-freeroom-box {
        margin: 90px 0 0 20px !important;
        width: calc(50% - 10px);
        // remove margin left on first and every third box
        &:first-child,
        &:nth-child(2n+3) {
          margin-left: 0 !important;
        }
        // change margin top on first 2 boxes
        &:nth-child(-n+2) {
          margin-top: 50px !important;
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-freerooms-list-view {
    .bn-freerooms-list-view__holder {
      display: block;
      .bn-freeroom-box {
        margin: 70px 0 0 0 !important;
        width: 100%;
        .bn-freeroom-box__content {
          padding: 20px 20px 90px 20px;
          .bn-freeroom-box__dates {
            margin-top: 20px;
          }
          .bn-freeroom-box__button {
            bottom: 20px;
            padding: 0 20px;
          }
        }
        // change margin top on second box
        &:nth-child(2) {
          margin-top: 70px !important;
        }
      }
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-freerooms-list-view {
    .bn-freerooms-list-view__holder {
      .bn-freeroom-box {
        margin-top: 55px !important;
        .bn-freeroom-box__labels {
          top: -35px;
          .bn-freeroom-box__label {
            height: 70px;
            margin-left: 5px;
            min-width: 70px;
            .bn-icon {
              font-size: 2.2em;
            }
            .bn-freeroom-box__amount-addition {
              font-size: 1.2em;
            }
            .bn-freeroom-box__amount {
              font-size: 1.6em;
            }
          }
        }
        // change margin top on first box
        &:first-child {
          margin-top: 35px !important;
        }
        // change margin top on second box
        &:nth-child(2) {
          margin-top: 55px !important;
        }
      }
    }
  }
}