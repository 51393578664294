/*  ==========================================================================
    UNITS
    List of some recurring units.

    INFO:
    - don't delete any unit!
    - use unit changes with care
    - if possible try to define all z-index values here
    - add your own units where they fit (not assignable here: "general")
    ========================================================================== */

/*
 * HEIGHTS
 */

// header
$unit__hgt--header-v1: 140px;
$unit__hgt--header-v1-minimized: 100px;
$unit__hgt--header-v1-sub-menu-arrow: 10px;
$unit__hgt--header-v3: 140px;
$unit__hgt--header-v3_tel: 30px;
$unit__hgt--header-v4_lang-menu: 30px;

// mobile header
$unit__hgt--mobile-header-v1: 150px;
$unit__hgt--mobile-header-v1-phone: 90px;

// vista
$unit__hgt--vista: 80vh;
$unit__hgt--vista-full-screen: 100vh;

// footer
$unit__hgt--footer-fixed-bar: 80px;

// misc
$unit__hgt--mobile-burger-menu: 60px;
$unit__hgt--quick-request-v1: $unit__hgt--footer-fixed-bar;

/*
 * WIDTHS
 */

// header
$unit__wdt--header-v3-sidebar: 25%;
$unit__wdt--header-v3-sidebar-desktop-large: 480px;
$unit__wdt--header-v3-sub-lvl-wrap: 20%;
$unit__wdt--header-v3-sub-lvl-wrap-desktop-large: 385px;

// mobile header
$unit__wdt--mobile-header-control-bar: 100%;

/*
 * Z-INDEX
 */

// mixins
$unit__zi--mixins-slider-dots-button: 5;
$unit__zi--mixins-waypoint: -100;

// header
$unit__zi--header: 40;
$unit__zi--header-v1-main-logo-image: 5;
$unit__zi--header-v3-sidebar: 100;
$unit__zi--header-v3-sub-menu: 5;
$unit__zi--header-v3-sub-menu-wrap: 100;
$unit__zi--header-v3-sub-menu-item: 5;

// mobile header
$unit__zi--mobile-header: 40;
$unit__zi--mobile-header-control-bar: 100;

// vista
$unit__zi--vista: 20;
$unit__zi--vista-arrows: 5;
$unit__zi--vista-dots: 5;
$unit__zi--vista-sticker: 5;
$unit__zi--vista-slogan: 5;
$unit__zi--vista-notice: 5;
$unit__zi--vista-scroll-down: 5;

// main
$unit__zi--main: 10;

// footer
$unit__zi--footer: 10;
$unit__zi--footer-top-references: 100;

// misc
$unit__zi--scroll-up: 35;
$unit__zi--quick-request: 35;
$unit__zi--pop-up: 200;

/*
 * GENERAL
 */
