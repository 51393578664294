/*  ==========================================================================
    PRICE OVERVIEW
    ========================================================================== */

/*
 * COLORS
 */



/*
 * MODULE
 */

.bn-price-overview {
  .bn-price-overview__holder {
    .bn-room-rates {
      @include price-list--v2();
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */