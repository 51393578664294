/*  ==========================================================================
    GENERAL
    Useful global helpers.

    INFO:
    - use it with care, its not a trash can!
    - contributions for the kickstarter are welcome!
    ========================================================================== */

// aspect ratio
@mixin aspect-ratio($width,$height) {
    height: 0;
    padding-top: ($height / $width) * 100%;
}

// clearfix
@mixin clearfix() {
    *zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// create table
@mixin display--table() {
    display: table;
    > * {
        display: table-row;
        height: 100%;
        width: 100%;
        > * {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

// reset table
@mixin display--table-reset() {
    display: initial;
    > * {
        display: initial;
        height: auto;
        width: auto;
        > * {
            display: initial;
            vertical-align: initial;
        }
    }
}

// loader
@mixin loader($clr,$size) {
    color: $clr;
    display: inline-block;
    font-size: $size;
    height: $size;
    line-height: $size;
    text-align: center;
    width: 100%;
    .bn-icon {
        animation: rotate normal 1s infinite linear;
        display: inline-block;
    }
}

// scrollbar
@mixin scrollbar($clr,$size) {
    &::-webkit-scrollbar {
        height: $size;
        width: $size;
    }
    &::-webkit-scrollbar-track {
        background: $clr__black;
    }
    &::-webkit-scrollbar-thumb {
        background: $clr;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgba($clr, 0.9);
    }
}

// waypoint
@mixin waypoint() {
    height: 1px;
    position: relative;
    visibility: hidden;
    width: 1px;
    z-index: $unit__zi--mixins-waypoint;
}