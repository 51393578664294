/*  ==========================================================================
    HEADER V1
    ========================================================================== */

@mixin header--v1() {
  background-color: $clr__white;
  border-bottom: 1px solid $clr__ci--primary;
  height: $unit__hgt--header-v1;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  // controls
  .bn-controls {
    height: 100%;
    > .bn-grid__row {
      height: 100%;
    }
    .bn-controls__left {
      max-width: 280px;
      position: relative;
    }
    .bn-controls__right {
      .bn-controls__right___top {
        font-size: 1.6em;
        height: 40%;
        padding-top: 15px;
        a {
          color: $clr__typo--primary;
          &:visited {
            color: $clr__typo--primary;
          }
          &:hover {
            color: $clr__ci--primary;
          }
        }
        > .bn-grid__col {
          align-items: center;
          display: flex;
          justify-content: flex-end;
        }
      }
      .bn-controls__right___bottom {
        height: 60%;
        > .bn-grid__col {
          @include clearfix();
        }
      }
    }
  }
  // main logo
  .bn-main-logo {
    &.bn-main-logo--above {
      display: flex;
      flex-direction: row;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      &:before {
        background-color: $clr__ci--primary;
        bottom: -1px;
        content: '';
        display: block;
        height: calc(100% + 1px - #{$unit__hgt--header-v1});
        left: -1px;
        position: absolute;
        width: calc(100% + 2px);
      }
      .bn-main-logo__image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 100%;
        z-index: $unit__zi--header-v1-main-logo-image;
        img {
          @include img--rendering-crisp-edges();
          max-width: 240px;
        }
        &.bn-main-logo__image--big {
          align-items: center;
          background-color: $clr__white;
          min-height: calc(#{$unit__hgt--header-v1} - 1px);
          padding: 20px 0;
        }
        &.bn-main-logo__image--small {
          align-items: flex-start;
          display: none;
          height: 100%;
          img {
            height: 60px;
          }
        }
        &.bn-main-logo__image--mobile {
          display: none;
        }
      }
    }
  }
  // top references
  .bn-top-references {
    &.bn-top-references--above {
      margin-right: 20px;
      .bn-top-references__item {
        margin-left: 10px;
        .bn-icon {
          font-size: 1.2em;
        }
        &:first-child {
          margin-left: 0;
        }
        &.bn-top-references__item--trigger {
          display: none;
        }
      }
    }
  }
  // tel
  .bn-tel {
    &.bn-tel--above {
      margin-right: 20px;
      .break {
        display: none;
      }
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--above {
      ul {
        @include clearfix();
        list-style-type: none;
        padding-left: 0;
        li {
          float: left;
          &:before {
            content: '|';
          }
          a {
            padding: 0 5px;
          }
          &.bn-lang-menu__item--act {
            a {
              font-weight: $typo__fw--bold;
            }
          }
          &:first-child {
            border-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  // main menu
  .bn-main-menu {
    float: right;
    height: 100%;
    padding-left: 150px;
    width: 100%;
    .bn-main-menu__lvl--0 {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      padding-left: 0;
      list-style-type: none;
      .bn-main-menu__item {
        > .bn-main-menu__arrow {
          display: none;
        }
      }
      > .bn-main-menu__item {
        height: 100%;
        position: relative;
        text-align: center;
        margin-left: 85px;
        > .bn-main-menu__link {
          align-items: center;
          color: $clr__typo--primary;
          display: flex;
          flex: 1;
          height: 100%;
          padding: 0 10px;
          > .bn-main-menu__titles {
            .bn-main-menu__title,
            .bn-main-menu__subtitle {
              text-transform: uppercase;
            }
            .bn-main-menu__title {
              font-size: 2.0em;
            }
            .bn-main-menu__subtitle {
              font-size: 1.5em;
            }
          }
          .bn-sub-menu-trigger {
            display: none;
          }
          &:visited {
            color: $clr__typo--primary;
          }
          &:hover {
            color: $clr__ci--primary;
          }
        }
        .bn-main-menu__sub-menu {
          border: 1px solid $clr__white;
          color: $clr__typo--secondary;
          display: none;
          left: 50%;
          position: absolute;
          text-align: left;
          top: 100%;
          transform: translateX(-50%);
          width: 300px;
          > .bn-main-menu__arrow {
            height: $unit__hgt--header-v1-sub-menu-arrow;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: -#{$unit__hgt--header-v1-sub-menu-arrow};
            width: 100%;
            &:before,
            &:after {
              background-color: $clr__ci--primary;
              content: '';
              height: 100%;
              position: absolute;
              top: 0;
              width: 50%;
            }
            &:before {
              left: -10px;
              transform: skew(65deg);
            }
            &:after {
              right: -10px;
              transform: skew(-65deg);
            }
          }
          .bn-main-menu__lvl-wrap {
            .bn-main-menu__lvl {
              list-style-type: none;
              padding-left: 0;
              .bn-main-menu__item {
                padding: 5px 0;
                .bn-main-menu__link {
                  color: $clr__typo--secondary;
                  font-size: 2.0em;
                  &:visited {
                    color: $clr__typo--secondary;
                  }
                  &:hover {
                    color: rgba($clr__typo--secondary, 0.5);
                  }
                }
                &.bn-main-menu__item--act,
                &.bn-main-menu__item--cur {
                  > .bn-main-menu__link {
                    color: $clr__ci--secondary;
                  }
                }
              }
              &.bn-main-menu__lvl--1 {
                background-color: $clr__ci--primary;
                padding: 20px 40px 20px 40px;
              }
              &.bn-main-menu__lvl--2 {
                padding-top: 5px;
                .bn-main-menu__item {
                  padding-left: 40px;
                  position: relative;
                  &:before {
                    content: '-';
                    display: block;
                    font-size: 2.0em;
                    left: 20px;
                    position: absolute;
                    text-align: center;
                    top: 5px;
                    width: 20px;
                  }
                  &.bn-main-menu__item--last {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }
        }
        &.bn-main-menu__item--first {
          .bn-main-menu__link {
            padding-left: 0;
          }
        }
        &.bn-main-menu__item--last {
          .bn-main-menu__link {
            padding-right: 0;
          }
          .bn-main-menu__sub-menu {
            left: auto;
            right: 0;
            transform: translateX(0);
          }
        }
        &:hover {
          .bn-main-menu__sub-menu {
            display: block;
          }
        }
        &.bn-main-menu__item--act,
        &.bn-main-menu__item--cur {
          > .bn-main-menu__link {
            color: $clr__ci--primary;
          }
        }
      }
    }
  }
  // minimized
  &.bn-header--minimized {
    height: $unit__hgt--header-v1-minimized;
    // controls
    .bn-controls {
      .bn-controls__right {
        .bn-controls__right___top {
          display: none;
        }
        .bn-controls__right___bottom {
          height: 100%;
        }
      }
    }
    // main logo
    .bn-main-logo {
      &.bn-main-logo--above {
        height: 100%;
        &:before {
          display: none;
        }
        .bn-main-logo__image {
          &.bn-main-logo__image--big {
            display: none;
          }
          &.bn-main-logo__image--small {
            display: flex;
          }
        }
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // full screen on
  @media #{$mq__full-screen--on} {
    position: fixed;
    // main menu
    .bn-main-menu {
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          .bn-main-menu__sub-menu {
            .bn-main-menu__lvl-wrap {
              .bn-main-menu__lvl {
                &.bn-main-menu__lvl--1 {
                  max-height: calc(100vh - #{$unit__hgt--header-v1} - #{$unit__hgt--header-v1-sub-menu-arrow});
                  overflow-y: auto;
                  &::-webkit-scrollbar {
                    width: 5px;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: $clr__ci--secondary;
                  }
                  &::-webkit-scrollbar-thumb:hover {
                    background: rgba($clr__ci--secondary, 0.9);
                  }
                }
              }
            }
          }
        }
      }
    }
    // minimized
    &.bn-header--minimized {
      // main menu
      .bn-main-menu {
        .bn-main-menu__lvl--0 {
          > .bn-main-menu__item {
            .bn-main-menu__sub-menu {
              .bn-main-menu__lvl-wrap {
                .bn-main-menu__lvl {
                  &.bn-main-menu__lvl--1 {
                    max-height: calc(100vh - #{$unit__hgt--header-v1-minimized} - #{$unit__hgt--header-v1-sub-menu-arrow});
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // desktop medium
  @media #{$mq__desktop--medium} {
    // main menu
    .bn-main-menu {
      padding-left: 100px;
    }
  }
  // desktop small
  @media #{$mq__desktop--small} {
    // main menu
    .bn-main-menu {
      padding-left: 50px;
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          .bn-main-menu__sub-menu {
            width: 250px;
            .bn-main-menu__lvl-wrap {
              .bn-main-menu__lvl {
                &.bn-main-menu__lvl--2 {
                  .bn-main-menu__item {
                    padding-left: 20px;
                    &:before {
                      left: 0;
                    }
                  }
                }
              }
            }
          }
          &.bn-main-menu__item--first {
            .bn-main-menu__sub-menu {
              left: 0;
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
  // laptop
  @media #{$mq__laptop} {
    // controls
    .bn-controls {
     .bn-controls__left {
       max-width: 200px;
     }
    }
    // main logo
    .bn-main-logo {
      &.bn-main-logo--above {
        .bn-main-logo__image {
          img {
            max-width: 160px;
          }
          &.bn-main-logo__image--big {
            padding: 20px 0;
          }
          &.bn-main-logo__image--small {
            img {
              height: 40px;
            }
          }
        }
      }
    }
    // main menu
    .bn-main-menu {
      padding-left: 25px;
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          > .bn-main-menu__link {
            padding: 0 5px;
          }
        }
      }
    }
  }
}