/*  ==========================================================================
    HTML
    ========================================================================== */

/*
 * COLORS
 */



/*
 * MODULE
 */

.bn-html {
  .bn-html__code {
    iframe {
      width: 100%;
    }
    &.bn-html__code--aspect-ratio {
      @include aspect-ratio(16,9);
      overflow: hidden;
      position: relative;
      iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */