/*  ==========================================================================
    SPACING
    ========================================================================== */
/*
 * COLORS
 */



/*
 * SIZES
 */

// default
$module-size__spacing--s: $bn-grid__spacing--s;
$module-size__spacing--m: $bn-grid__spacing--m;
$module-size__spacing--l: $bn-grid__spacing--l;

// tablet
$module-size__spacing-tablet--s: $bn-grid__spacing-tablet--s;
$module-size__spacing-tablet--m: $bn-grid__spacing-tablet--m;
$module-size__spacing-tablet--l: $bn-grid__spacing-tablet--l;

// phone
$module-size__spacing-phone--s: $bn-grid__spacing-phone--s;
$module-size__spacing-phone--m: $bn-grid__spacing-phone--m;
$module-size__spacing-phone--l: $bn-grid__spacing-phone--l;

/*
 * MODULE
 */

.bn-spacing {
  &.bn-spacing--s {
    > .bn-grid__row {
      > .bn-grid__col {
        height: $module-size__spacing--s;
      }
    }
  }
  &.bn-spacing--m {
    > .bn-grid__row {
      > .bn-grid__col {
        height: $module-size__spacing--m;
      }
    }
  }
  &.bn-spacing--l {
    > .bn-grid__row {
      > .bn-grid__col {
        height: $module-size__spacing--l;
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-spacing {
    &.bn-spacing--s {
      > .bn-grid__row {
        > .bn-grid__col {
          height: $module-size__spacing-tablet--s;
        }
      }
    }
    &.bn-spacing--m {
      > .bn-grid__row {
        > .bn-grid__col {
          height: $module-size__spacing-tablet--m;
        }
      }
    }
    &.bn-spacing--l {
      > .bn-grid__row {
        > .bn-grid__col {
          height: $module-size__spacing-tablet--l;
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-spacing {
    &.bn-spacing--s {
      > .bn-grid__row {
        > .bn-grid__col {
          height: $module-size__spacing-phone--s;
        }
      }
    }
    &.bn-spacing--m {
      > .bn-grid__row {
        > .bn-grid__col {
          height: $module-size__spacing-phone--m;
        }
      }
    }
    &.bn-spacing--l {
      > .bn-grid__row {
        > .bn-grid__col {
          height: $module-size__spacing-phone--l;
        }
      }
    }
  }
}