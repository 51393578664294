/*  ==========================================================================
    CONTENTCLUSTER
    ========================================================================== */

@mixin cc--box($accent,$bg-primary,$bg-secondary,$bg-tertiary,$typo-primary,$typo-secondary,$typo-tertiary) {
  .bn-cc-box {
    margin-top: 40px;
    > div {
      display: flex;
    }
    .bn-cc-box__content {
      background-color: $bg-primary;
      order: 2;
      padding: 40px;
      .bn-cc-box__headlines {
        display: flex;
        justify-content: space-between;
        .bn-cc-box__date {
          color: $typo-primary;
          display: none;
          font-size: 1.6em;
          font-weight: $typo__fw--bold;
          font-family: $typo__ff--secondary;
          margin-left: auto;
          order: 2;
          padding-left: 20px;
          white-space: nowrap;
        }
        .bn-cc-box__headline {
          @include typo--subheadline($typo-primary);
        }
        .bn-cc-box__subheadline {
          @include typo--paragraph($typo-primary,$typo-primary);
          text-transform: uppercase;
        }
      }
      .bn-cc-box__text {
        @include typo--rte($typo-primary,$accent);
        @include typo--list-lock();
        @include typo--table-lock();
        margin-top: 20px;
      }
      .bn-cc-box__button {
        margin-top: 20px;
        .bn-button {
          @include button--text($typo-primary,$accent);
          letter-spacing: 2px;
          &:hover {
            color: $clr__white !important;
            text-decoration: underline;
          }
        }
      }
    }
    .bn-cc-box__image {
      display: block;
      overflow: hidden;
      margin-right: 20px;
      max-width: 35%;
      order: 1;
      position: relative;
      width: 100%;
      img {
        @include img--cover-v2();
        left: 0;
        position: absolute;
        top: 0;
      }
    }
    &:first-child {
      margin-top: 0;
    }
    // recolor every second and third box
    &.bn-cc-box--ci-secondary {
      .bn-cc-box__content {
        background-color: $bg-secondary;
        .bn-cc-box__headlines {
          .bn-cc-box__date {
            color: $typo-tertiary;
          }
          .bn-cc-box__headline {
            @include typo--subheadline($typo-tertiary);
          }
          .bn-cc-box__subheadline {
            @include typo--paragraph($typo-tertiary,$typo-tertiary);
          }
        }
        .bn-cc-box__text {
          @include typo--rte($typo-tertiary,$accent);
        }
        .bn-cc-box__button {
          .bn-button {
            @include button--text($typo-tertiary,$accent);
            &:hover {
              color: $typo-tertiary !important;
            }
          }
        }
      }
    }
    &.bn-cc-box--ci-tertiary {
      .bn-cc-box__content {
        background-color: $bg-tertiary;
        .bn-cc-box__headlines {
          .bn-cc-box__date {
            color: $typo-tertiary;
          }
          .bn-cc-box__headline {
            @include typo--subheadline($typo-tertiary);
          }
          .bn-cc-box__subheadline {
            @include typo--paragraph($typo-tertiary,$typo-tertiary);
          }
        }
        .bn-cc-box__text {
          @include typo--rte($typo-tertiary,$accent);
        }
        .bn-cc-box__button {
          .bn-button {
            @include button--text($typo-tertiary,$accent);
            &:hover {
              color: $typo-tertiary !important;
            }
          }
        }
      }
    }
    // reposition image on every second box
    &.bn-cc-box--odd {
      .bn-cc-box__content {
        order: 1;
      }
      .bn-cc-box__image {
        margin-left: 20px;
        margin-right: 0;
        order: 2;
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // tablet
  @media #{$mq__tablet} {
    .bn-cc-box {
      .bn-cc-box__image {
        display: none;
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    .bn-cc-box {
      .bn-cc-box__content {
        .bn-cc-box__headlines {
          display: block;
          .bn-cc-box__date {
            margin-bottom: 10px;
            margin-left: 0;
            padding-left: 0;
          }
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-cc-box {
      margin-top: 20px;
      .bn-cc-box__content {
        padding: 20px;
      }
    }
  }
}