/*  ==========================================================================
    QUOTE V3
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__quote-v3--border: $clr__ci--primary;
$module-clr__quote-v3--signet: $clr__ci--primary;
$module-clr__quote-v3--text: $clr__ci--primary;

/*
 * MODULE
 */

.bn-quote-v3 {
  .bn-quote-v3__frame {
    padding: 0 40px;
    > div {
      &:first-child {
        position: relative;
        padding: 10px 0;
      }
    }
    .bn-quote-v3__text {
      @include typo--quote($module-clr__quote-v3--text);
      text-align: center;
    }
    .bn-quote-v3__quotation-mark {
      position: absolute;
      z-index: 5;
      .bn-icon {
        color: $module-clr__quote-v3--signet;
        font-size: 3.5em;
      }
      &.bn-quote-v3__quotation-mark--open {
        left: -40px;
        top: 0;
      }
      &.bn-quote-v3__quotation-mark--close {
        bottom: 0;
        right: -40px;
      }
    }
    .bn-quote-v3__author {
      @include typo--quote-author($module-clr__quote-v3--text);
      margin-top: 20px;
      text-align: center;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-quote-v3 {
    .bn-quote-v3__frame {
      .bn-quote-v3__quotation-mark {
        .bn-icon {
          font-size: 2.5em;
        }
      }
      .bn-quote-v3__author {
        margin-top: 10px;
      }
    }
  }
}