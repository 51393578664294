/*  ==========================================================================
    TRACKING
    ========================================================================== */

/*
 * COLORS
 */



/*
 * MODULE
 */

.bn-tracking {
  .bn-tracking__code {
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */